/* Fonts */
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/* Default */

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

/* App */

.site-loader {
  opacity: 1;
  z-index: 1000;
  transform: translateY(0%);
  transition: opacity 1.5s ease, transform 1s ease;
}

.site-loader.hidden {
  position: fixed;
  opacity: 0;
  transform: translateY(.5%);
}

/* Port and Contact titles */

.title-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.title-symbol-1 {
  height: 15px;
}

.title-symbol-2 {
  height: 10px;
  margin-right: 10px;
  border-radius: 20px;
}
.title-symbol-3 {
  height: 10px;
  margin-left: 10px;
  border-radius: 20px;
}
.title-symbol-4 {
  height: 15px;
}

/* NavBar */
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 98;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  /* background-color: RGB(157,186,224); */
  background-color: RGB(149, 183, 227);
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 700;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.5;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover {
  opacity: 0.7;
}

nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* Banner */
.banner {
  margin-top: 0;
  padding: 160px 10px 80px 10px;
  background-image: linear-gradient(
      rgb(20, 126, 251, 0.8),
      rgba(20, 126, 251, 0.3)
    ),
    url("./assets/img/banner-bg-10.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color: white;
}
.banner p {
  color: white;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner-logos-parent {
  padding-top: 20px;
  padding-bottom: 40px;
}

.banner .banner-logos-col {
  display: flex;
  justify-content: space-between;
}

@keyframes softPulse {
  0% {
    transform: scale(1);
    box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.2);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.4);
  }
  100% {
    transform: scale(1);
    box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.2);
  }
}

.banner-logos-contact {
  border-radius: 8px;
  padding: 8px;
  background-color: whitesmoke;
  animation: softPulse 3s ease-in-out infinite;
  cursor: pointer;
}

.banner-logos {
  border-radius: 8px;
  padding: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: whitesmoke;
  cursor: pointer;
}

.banner-logos:hover {
  opacity: 0.9;
}

.banner-headshot {
  animation: morph 8s ease-in-out infinite;
  width: 100%;
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%;
  }
  50% {
    border-radius: 60% 70% 30% 40%;
  }
  100% {
    border-radius: 60% 40% 30% 70%;
  }
}

/* Skills */
.skills {
  display: flex;
}

.skills .content {
  margin-top: 70px;
}

.skills .content .row {
  display: flex;
  align-items: center;
}

.skills p {
  border-right: 3px solid white;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  padding-top: 10px;
}

.skills .logos {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.skills ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}
.skills img {
  height: 3rem;
  width: 4rem;
  opacity: 0.9;
}
.skills img:hover {
  transition: 0.2s ease-in-out;
  scale: 1.03;
  opacity: 1;
}

/* Certs */
.certs {
  display: flex;
}

.certs .content {
  margin-top: 10px;
}

.certs .content .row {
  display: flex;
  align-items: center;
}

.certs p {
  border-right: 3px solid white;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
  padding-top: 10px;
}

.certs .logos {
  display: flex;
  padding-top: 10px;
  padding-left: 12%;
}

.certs ul {
  list-style: none;
  display: flex;
  flex-direction: row;
}
.certs img {
  height: 42px;
  width: 68px;
  opacity: 0.9;
}
.certs img:hover {
  transition: 0.2s ease-in-out;
  scale: 1.03;
  opacity: 1;
  cursor: pointer;
}

/* Portfolio */

.projects {
  display: flex;
  padding-bottom: 100px;
  padding-top: 100px;
  background: linear-gradient(rgb(157, 186, 224), rgb(20, 126, 251, 0.8));
}

.projects .content {
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  min-height: 400px;
  background-color: whitesmoke;
}

.projects h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
  align-self: center;
  color: rgb(0, 0, 0, 0.75);
}

/* Project Card */

.boarder-box {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  margin: 10px;
}

.boarder-box:hover:before {
  content: "";
  background-color: RGB(149, 183, 227);
  height: 100%;
  width: 100%;
  opacity: 0.9;
  position: absolute;
  border-radius: 28px;
  top: 0%;
  left: 0%;
  z-index: 1;
  transition: all 0.3s ease-in 0s;
}

.boarder-box img {
  width: 100%;
}

.boarder-box .box-content {
  text-align: center;
  width: 100%;
  opacity: 0;
  bottom: 30%;
  position: absolute;
  left: 0;
  z-index: 2;
  padding: 36px;
  transition: all 0.3s ease-out 0.1s;
}

.boarder-box:hover .box-content {
  opacity: 1;
  bottom: 20%;
}

.boarder-box .box-content img {
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 4px;
  background-color: white;
  opacity: 0.8;
  border-radius: 20px;
}

.boarder-box .box-content img:hover {
  scale: 1.03;
  background-color: white;
  opacity: 1;
}

.boarder-box .box-content h4 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: white;
}

.project-card-btn {
  background-color: transparent;
  border: 1px whitesmoke solid;
  color: rgb(0, 0, 0, 0.75);
}

.project-card-btn:hover {
  scale: 1.03;
  opacity: 1;
}

.project-card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Details */

.details {
  width: 25rem;
  padding: 4px 10px 0px 10px;
  background-color: whitesmoke;
}
.details-card-img-top {
  /* margin-top: 4px; */
  margin: 4px 4px 4px 4px;
  border-radius: 4px;
}

.details-btn {
  background-color: transparent;
  border: 1px transparent solid;
  color: rgb(0, 0, 0, 0.75);
}

.details-btn-x {
  opacity: 0.7;
  padding: 5px;
  cursor: pointer;
}

.details-btn:hover,
.details-btn-x:hover {
  scale: 1.03;
  opacity: 1;
}

/* Contact */

.contact {
  padding-bottom: 80px;
  padding-top: 10px;
  display: flex;
  background-color: rgb(20, 126, 251, 0.8);
}

.contact .content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  min-height: 400px;
  background-color: whitesmoke;
}

.contact h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
  align-self: center;
  color: rgb(0, 0, 0, 0.75);
}

.contact .logos div {
  color: RGB(20, 126, 251);
  opacity: 0.8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 700;
}

.contact .logos-parent {
  display: flex;
  justify-content: space-evenly;
}

.contact .pro-image {
  width: 50%;
  height: auto;
  border-radius: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}

.contact .logos div:hover {
  transition: 0.2s ease-in-out;
  scale: 1.03;
  opacity: 1;
  cursor: pointer;
}

.contact .logos {
  display: flex;
  padding: 2%;
  padding-left: 10%;
}

.contact .logos img {
  margin-right: 8px;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: whitesmoke;
}

.contact .logos-row {
  display: flex;
  align-content: space-between;
}

/* Footer */

.footer {
  background-color: rgb(20, 126, 251, 0.8);
  padding: 10px 2% 10px 2%;
}

.footer .content {
  display: flex;
  flex-direction: column;
}

.footer .content .row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .content .row-1 .symbols {
  display: flex;
}

.footer .symbols img {
  opacity: 0.7;
  margin-left: 10px;
}

.footer .symbols img:hover {
  transition: 0.2s ease-in-out;
  opacity: 1;
}

.footer .row-2 {
  padding-top: 2px;
  padding-bottom: 4px;
  color: rgb(250, 250, 250, 0.5);
  font-size: 10px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
}

/* Cookie Alert */

.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.85
  );
  color: white;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  z-index: 1000;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
}

.cookie-message {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.5;
}

.accept-cookie-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: rgb(20, 126, 251, 0.8);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accept-cookie-btn:hover {
  background-color: rgb(20, 126, 251, 0.6);
}

/* Mobile Responsive */
@media (max-width: 600px) {
  .cookie-consent-banner {
    padding: 15px;
  }

  .cookie-message {
    font-size: 14px;
  }

  .accept-cookie-btn {
    font-size: 14px;
    padding: 8px 15px;
  }
}

/* Media Queries */

/*  Medium */
@media (max-width: 768px) {
  .skills .content .row {
    display: flex;
    justify-content: center;
  }
  .skills .content {
    display: block;
    margin-top: 25px;
  }
  .skills p {
    border-right: none;
    border-bottom: 3px solid white;
    padding-right: 0px;
    margin-bottom: 10px;
    text-align: center;
  }
  .skills ul {
    padding-left: 0rem;
  }
  .skills .logos {
    text-align: center;
  }
  .certs .content .row {
    display: flex;
    justify-content: center;
  }
  .certs .content {
    display: block;
    margin-top: 25px;
  }
  .certs p {
    border-right: none;
    border-bottom: 3px solid white;
    padding-right: 0px;
    margin-bottom: 10px;
    text-align: center;
  }
  .certs ul {
    padding-left: 0rem;
  }
  .certs .logos {
    text-align: center;
    justify-content: center;
    padding-left: 0px;
  }
  .banner p {
    margin-bottom: 25px;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link {
    padding: 0 15px;
  }
  .banner .banner-logos-col {
    justify-content: space-around;
  }
  .banner .aligh-items-center {
    text-align: center;
  }
}

/* Small */
@media (max-width: 576px) {
  .proj-txtx img {
    height: 30px;
    width: 30px;
  }
  .proj-imgbx:hover::before {
    width: 100%;
    border-radius: 14px;
  }
  .proj-imgbx:hover .proj-txtx {
    top: 50%;
  }
  .proj-txtx {
    left: 50%;
  }
  .contact .logos-parent {
    flex-direction: column;
    align-items: center;
  }
  .contact .pro-image {
    margin-right: 0px;
  }
  .contact .logos {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0%;
    padding-left: 0%;
    display: flex;
  }
  .contact .pro-image {
    height: 220px;
    width: 300px;
  }
  .contact .logos-row {
    min-width: 75%;
    max-width: 90%;
  }
}
